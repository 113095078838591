@import url('https://fonts.googleapis.com/css?family=Quattrocento+Sans');
@import url('https://fonts.googleapis.com/css?family=Audiowide');

$primary: #1A2184;
$secondary: #388087; //668539
$tertiary: #1A2124;
$white: #FFFFFF !default;
$black: #161C2D !default;
$body-bg: #F6F6F2;
$body-bg-alt: #1A2124;

// $body-color: $primary;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "black": $black,
  "white": $white,
  "dark": $black,
);

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.site-header {
  min-height: 60px;
  background: $body-bg-alt;
}

.nav-link {
  color: white !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.navbar-phone {
  padding-top: 10px;
}

// span.carousel-control-next-icon {
//   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff0000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
// }

// span.carousel-control-prev-icon {
//   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff0000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
// }

// .carousel-inner .item {
//   height: 400px;  /* add this */
// }

.navbar-nav {
  padding-top: 17px;
}

@media only screen and (max-width: 764px) {
  .navbar-nav {
    padding-top: 20px;
  }
}


#branding {
  background-color: white;
  padding-top: 8px;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;

  -webkit-box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

body {
  font-family: "Quattrocento Sans", serif !important;
  //font-family: 'Audiowide', sans-serif !important;
}

p,
a {
  font-size: 14pt;
  font-family: Arial, Helvetica, sans-serif;
}

h2 {
  margin-bottom: 15px !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700 !important;
}

hr {
  width: 75px;
  border-top: 4px solid $tertiary  !important;
}

.heroSubheader {
  font-size: 25px;
}

.mask {
  min-height: 400px;
  background-color: rgba(255, 255, 255, 0.8);
  margin: 0;
}

@media only screen and (min-width: 1000px) {
  .mask {
    min-height: 600px;
  }
}

.hero-container {
  border-top: 2px solid $tertiary;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.gradient-custom {
  background: radial-gradient(50% 123.47% at 50% 50%, #00ff94 0%, #720059 100%),
    linear-gradient(121.28deg, #669600 0%, #ff0000 100%),
    linear-gradient(360deg, #0029ff 0%, #8fff00 100%),
    radial-gradient(100% 164.72% at 100% 100%, #6100ff 0%, #00ff57 100%),
    radial-gradient(100% 148.07% at 0% 0%, #fff500 0%, #51d500 100%);
  background-blend-mode: screen, color-dodge, overlay, difference, normal;
}

// .carousel-indicators {
//   border-radius: 50%;
//   width: 10px;
//   height: 10px;
// }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

// Spacing
$spacer: 1rem !default;
$spacers: (
  ) !default;

/* beautify ignore:start */
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25), // 4px
    2: ($spacer * .5), // 8px
    3: ($spacer * .75), // 12px
    4: $spacer, // 16px
    5: ($spacer * 1.5), // 24px
    6: ($spacer * 2), // 32px
    7: ($spacer * 2.5), // 40px
    8: ($spacer * 3), // 48px
    9: ($spacer * 4), // 64px
    10: ($spacer * 5), // 80px
    11: ($spacer * 6), // 96px
    12: ($spacer * 8), // 128px
    13: ($spacer * 10), // 160px 
    14: ($spacer * 12), // 192px
    15: ($spacer * 16), // 256px                                                     
    16: ($spacer * 25), // 400px
  ),
  $spacers
);
/* beautify ignore:end */

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';